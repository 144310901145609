import axios from "axios";

const API_URL = process.env.VUE_APP_URL_API;

export class ApiCategory {

    constructor() { }

    async loadAllCategory(data,token) {
        return await axios.post(API_URL + 'category',data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async addCategory(data, token) {
        return await axios.post(API_URL + 'category/store', data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async delCategory(id, token) {
        return await axios.delete(API_URL + 'category/delete/'+id, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async updateCategory(id,data, token) {
        return await axios.post(API_URL + 'category/update/'+id, data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async addSubCategory(data, token) {
        return await axios.post(API_URL + 'sub-category/store', data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async delSubCategory(id, token) {
        return await axios.delete(API_URL + 'sub-category/delete/'+id, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async updateSubCategory(id,data, token) {
        return await axios.post(API_URL + 'sub-category/update/'+id, data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async selectCategories( token) {
        return await axios.get(API_URL + 'manager/select-categories', this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    headers_api(token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }
    }

}