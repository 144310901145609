<template>
  <q-list bordered separator :style="isSelected==true?'background-image: linear-gradient(to bottom right,white,#E3EEFF);':''">
    <q-item>
      <q-item-section>
        <q-item-label>{{ name }}</q-item-label>
        <q-item-label lines="1"
          ><q-btn dense flat color="primary" size="sm" @click="showDetail"
            >Detail</q-btn
          ></q-item-label>
        
      </q-item-section>
      <q-item-section side>
        <div class="q-gutter-xs">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            color="green"
            class="q-mr-sm"
            @click="dialUptCategory"
          ></q-btn>
          <q-btn
            size="sm"
            color="red"
            outline
            round
            icon="delete"
            @click="dialDelCategory"
          ></q-btn>
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="isDeleteDialoagCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-red text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        {{ name }}
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          label="Delete Category"
          color="red"
          @click="btnDeleteCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isEditDialoagCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-green text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        <q-input
          dense
          rounded
          outlined
          placeholder="Add Category"
          v-model="categoryName"
        />
        
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          color="green"
          label="Update Category"
          @click="btnUpdateCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
// import { ApiCategory } from "../../api/ApiCategory";

export default {
  props: ["id", "name", "order_num"],
  emits: ["deleteCat", "updateCat"],
  setup(props, context) {
    const store = useStore();
    // const api_category = new ApiCategory();
    const isSelected = ref(false)

    const isDeleteDialoagCategory = ref(false);
    const isEditDialoagCategory = ref(false);
    const categoryName = ref(props.name);

    const catId = computed(function(){
        return store.getters['cat/categoryId']
    })   

    watch(catId, function (newValues) {
        if(newValues==props.id){
            isSelected.value = true
        }else{
            isSelected.value = false
        }
    });

    function showDetail() {
      store.dispatch("cat/loadSubCategory", props.id);
    }

    function dialUptCategory() {
      isEditDialoagCategory.value = true;
    }

    function dialDelCategory() {
      isDeleteDialoagCategory.value = true;
    }

    function btnDeleteCategory() {
      context.emit("deleteUser", props.id);
      isDeleteDialoagCategory.value = false;
    }

    function btnUpdateCategory() {

        context.emit("updateCat", {id: props.id, name: categoryName.value})
        isEditDialoagCategory.value = false;
    }
    

    return {
      isDeleteDialoagCategory,
      isEditDialoagCategory,
      categoryName,
      isSelected,

      dialUptCategory,
      dialDelCategory,
      showDetail,
      btnDeleteCategory,
      btnUpdateCategory,

    };
  },

 
};
</script>
