<template>
  <div class="q-pa-md">
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="row">
          <div class="col-12">
            <q-btn
              rounded
              color="primary"
              size="sm"
              @click="showDialogCreateUser"
              >CREATE</q-btn
            >
          </div>
        </div>
        <div class="row q-pt-sm">
          <div class="col-12">
            <q-list bordered separator v-if="itemUser.length > 0">
              <user-item
                v-for="item in itemUser"
                :key="item.id"
                :id="item.id"
                :name="item.name"
                :username="item.username"
                @delUser="actDelUser"
              ></user-item>
            </q-list>
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="isAddDialUser">
      <q-card style="min-width: 350px">
        <q-form @submit="CreateUser">
          <q-card-section class="bg-primary text-white">
            <div class="text-h6">Dialog</div>
          </q-card-section>

          <q-card-section class="q-pt-sm">
            <q-input
              dense
              rounded
              outlined
              placeholder="Name"
              v-model="name"
              :rules="[
                (val) => (val !== null && val !== '') || 'Name is required',
              ]"
            />
            <q-input
              dense
              rounded
              outlined
              placeholder="Username"
              v-model="userName"
              :rules="[
                (val) => (val !== null && val !== '') || 'Username is required',
              ]"
            />

            <q-input
              type="number"
              dense
              rounded
              outlined
              placeholder="Handphone Number"
              v-model="no_hp"
              :rules="[
                (val) =>
                  (val !== null && val !== '') ||
                  'Handphone Number is required',
              ]"
            />

            <q-input
              type="password"
              dense
              rounded
              outlined
              placeholder="Password"
              v-model="password"
              :rules="[
                (val) => (val !== null && val !== '') || 'Password is required',
              ]"
            />

            <q-input
              dense
              rounded
              outlined
              placeholder="Kode"
              v-model="code"
              maxlength="4"
              :rules="[
                (val) => (val !== null && val !== '') || 'Kode is required',
              ]"
            />
          </q-card-section>

          <q-card-actions align="right" class="text-dark">
            <q-btn flat label="Cancel" v-close-popup />
            <q-btn
              size="sm"
              type="submit"
              outline
              rounded
              color="primary"
              label="Add User"
            />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { ApiUser } from "../../api/ApiUser";
import UserItem from "../../components/user/UserItem.vue";
import { useStore } from "vuex";

const api_user = new ApiUser();

export default {
  components: {
    UserItem,
  },
  setup() {
    const store = useStore();
    const itemUser = ref([]);
    const userName = ref("");
    const password = ref(null);
    const code = ref(null);
    const name = ref(null);
    const no_hp = ref(null);
    const isAddDialUser = ref(false);

    onMounted(function () {
      fetchUser();
    });

    function fetchUser() {
      //   console.log(store.getters["token"]);
      api_user.loadAlluser(store.getters["token"]).then((res) => {
        if (res.data) {
          itemUser.value = res.data;
        }
      });
    }

    function showDialogCreateUser() {
      onReset();
      isAddDialUser.value = true;
    }

    function btnAddUser() {}

    function actDelUser(id) {
      api_user.delUser(id, store.getters["token"]).then((res) => {
        if (res.data) {
          fetchUser();
        }
      });
    }

    function onReset() {
      name.value = null;
      userName.value = null;
      password.value = null;
      code.value = null;
      no_hp.value = null;
    }

    function CreateUser() {
      var data = {
        name: name.value,
        username: userName.value,
        password: password.value,
        otp_code: code.value,
        no_handphone: no_hp.value,
      };
      console.log(data)
      api_user
        .addUser(data, store.getters["token"])
        .then((res) => {
          if (res.data) {
            fetchUser();
            isAddDialUser.value = false
            
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return {
      itemUser,
      userName,
      isAddDialUser,
      password,
      code,
      name,
      no_hp,

      showDialogCreateUser,
      CreateUser,
      actDelUser,
      btnAddUser,
    };
  },
};
</script>
