import axios from "axios";

const API_URL = process.env.VUE_APP_URL_API;
export class ApiTransactionAssets {

    constructor() { }
    data_url = process.env.VUE_APP_URL_API;
    async loadAllTransactionAsset(next,page, data, token) {
        if(next==false){
            return await axios.post(API_URL + 'transaction-upload', data, this.headers_api(token)).catch((err) => { throw err.response.status });
        }else{
            return await axios.post(API_URL + 'transaction-upload?page='+page, data, this.headers_api(token)).catch((err) => { throw err.response.status });
        }
        
    }
    
    async addTransAsset(data, token) {
        return await axios.post(API_URL + 'transaction-upload/store', data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async delTransAsset(id, token) {
        return await axios.delete(API_URL + 'transaction-upload/delete/' + id, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async updateTransAsset(id, data, token) {
        return await axios.post(API_URL + 'transaction-upload/update/' + id, data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async updateTransUpdateDetail(id, data, token) {
        return await axios.post(API_URL + 'transaction-upload/update-detail-status/' + id, data, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async storeUploadFile(data, token) {
        return await axios.post(API_URL + 'transaction-upload/store-file', data, this.headers_file(token)).catch((err) => { throw err.response.status });
    }

    async searchFile(next,page ,data, token) {
        if(next==false){
            return await axios.post(API_URL + 'transaction-upload/search-file', data, this.headers_file(token)).catch((err) => { throw err.response.status });
        }else{
            return await axios.post(API_URL + 'transaction-upload/search-file?page='+page, data, this.headers_file(token)).catch((err) => { throw err.response.status });
        }   
        
    }

    async delTransSvg(id, token) {
        return await axios.delete(API_URL + 'transaction-upload/delete-svg/' + id, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    headers_file(token) {
        return {

            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'multipart/form-data'
            }
        }
    }

    headers_api(token) {
        return {

            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }
    }
}