import  ApiCategory from "./ApiCategory"


export class Manager{

    constructor() { }
    
    async loadSelectCategories(token){
        const api_category = ApiCategory()
        console.log(token)
        let data =[]
        await api_category.selectCategories(token).then((res) => {
            if (res.data) {
                const dt = JSON.stringify(res.data)
                data = JSON.parse(dt)
            }
        }).catch((err) => {
            console.log('error ' + err);
        })

        return data
    }

    selectView(){
        return [
            {label: 'kotak', value: 'kotak'},
            {label: 'fullscreen', value: 'fullscreen'},
        ];
    }
}