<template>
  <div class="col-6 q-pl-lg q-pr-lg">
    <div class="row">
      <div class="col-6">
        <q-btn
          rounded
          color="primary"
          size="sm"
          @click="showDialogCreateCategory"
          >CREATE</q-btn
        >
      </div>
      <div class="col-6">
        <div class="q-pt-sm text-right">Category</div>
      </div>
    </div>
    <div class="row q-pt-sm">
      <div class="col-12">
        <q-input rounded outlined label="Search" dense v-model="searchCat">
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <div class="row q-pt-sm">
      <div class="col-12">
        <div v-if="items.length > 0">
          <category-item
            v-for="item in items"
            :key="item.id"
            :id="item.id"
            :name="item.name"
            :order_num="item.order_num"
            @deleteCat="actDelete"
            @updateCat="actUpdate"
          ></category-item>
        </div>
      </div>
    </div>
  </div>

  <q-dialog v-model="isAddDialoagCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        <q-input
          dense
          rounded
          outlined
          placeholder="Add Category"
          v-model="categoryName"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          color="primary"
          label="Add Category"
          @click="CreateCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import { ApiCategory } from "../../api/ApiCategory";
import CategoryItem from "../../components/category/CategoryItem.vue";

export default {
  components: {
    CategoryItem,
  },

  setup() {
    const store = useStore();
    const categoryName = ref("");
    const isAddDialoagCategory = ref(false);
    const searchCat = ref("");
    let items = ref([]);
    let itemdDup = ref([]);

    const api_category = new ApiCategory();

    onBeforeMount(() => {
      store.dispatch("tryLogin");
      fectchCategories();
    });

    function fectchCategories() {
      const token = store.getters["token"];
    //   console.log(token)

      api_category
        .loadAllCategory(null,token)
        .then((res) => {
          if (res.data) {
            let data = JSON.stringify(res.data.data);
            items.value = JSON.parse(data);
            itemdDup.value = JSON.parse(data);
          }
        })
        .catch((err) => {
          items = [];
        //   console.log(token)
          console.log("error categoryloadDataCategory" + err);
        });
    }

    function actDelete(id) {
      api_category
        .delCategory(id, store.getters["token"])
        .then((res) => {
          if (res.data) {
            fectchCategories();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function actUpdate(item){
          api_category
        .updateCategory(
          item.id,
          { name: item.name},
          store.getters["token"]
        )
        .then((res) => {
          if (res.data) {
            fectchCategories();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    watch(searchCat, function (newValues) {
      if (newValues != "" || newValues == null) {
        const searchFilter = items.value;
        items.value = searchFilter.filter(
          (x) => x.name.toLowerCase().indexOf(searchCat.value) > -1
        );
      } else {
        items.value = itemdDup.value;
      }
    });

    // function inSearchTrans() {
    //   if (searchCat.value == "") {
    //     items.value = itemdDup.value;
    //   } else {
    //     const searchFilter = items.value;
    //     items.value = searchFilter.filter(
    //       (x) => x.name.toLowerCase().indexOf(searchCat.value) > -1
    //     );
    //   }
    // }

    // function filterCategories(){
    //     console.log(this.items)
    //     if(searchCat.value==""){
    //         this.items = this.itemdDup
    //     }else{
    //         const searchFilter = items.value
    //         items.value = searchFilter.filter(x=>x.name.toLowerCase().indexOf(searchFilter) > -1)
    //     }
    // }

    function showDialogCreateCategory() {
      isAddDialoagCategory.value = true;
    }

    function CreateCategory() {
      api_category
        .addCategory({ name: categoryName.value }, store.getters["token"])
        .then((res) => {
          if (res.data) {
            store.dispatch("cat/loadCategories").then(() => {
              setTimeout(() => {
                items.value = JSON.parse(store.getters["cat/category"]);
                itemdDup.value = JSON.parse(store.getters["cat/category"]);
              }, 1200);
              isAddDialoagCategory.value = false;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      items,
      categoryName,
      isAddDialoagCategory,
      searchCat,

      showDialogCreateCategory,
      CreateCategory,
      actDelete,
      actUpdate
    };
  },
};
</script>

<style scoped>
.q-card {
  border: 1px solid #2196f3;
}
.q-field--outlined .q-field__control {
  border: 1px solid #2196f3;
}
</style>
