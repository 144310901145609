<template>
  <div class="col-6 q-pl-lg q-pr-lg">
    <div class="row" v-if="transId">
      <div class="col-6">
        <q-btn
          rounded
          color="primary"
          size="sm"
          @click="showDialogCreateTransDet"
          >CREATE</q-btn
        >
      </div>
      <div class="col-6">
        <div class="q-pt-sm text-right">{{freeText}}</div>
      </div>
    </div>
    <div class="row q-pt-sm">
      <div class="col-12">
        <div v-if="subItems.length > 0">
          <trans-det-asset-item
            v-for="item in subItems"
            :key="item.id"
            :id="item.id"
            :name="item.image_name"
            :link="item.image_link"
            :premium="item.is_premium"
            :date="item.created_at"
            :screen="item.screen_view"
          ></trans-det-asset-item>
        </div>
      </div>
    </div>
    <div class="row q-pt-sm" v-if="subItems.length > 0">
      <div class="col-12">
        <q-pagination
          v-model="page"
          :max="maxPage"
          @click="btnPage()"
        ></q-pagination>
      </div>
    </div>
  </div>

  <q-dialog v-model="isAddDialFile">
    <q-card style="min-width: 350px; max-width: 500px">
      <q-form @submit="addFileTrans">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">Dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-sm">
          <q-file
            dense
            rounded
            outlined
            multiple
            use-chips
            label="Insert File"
            v-model="addFile"
            name="file_svg"
            accept="image/*"
          >
            <template v-slot:prepend> <q-icon name="attach_file" /> </template
          ></q-file>

          <q-checkbox v-model="isPremium" label="Premium" color="green" /><br/>

          <q-btn-toggle v-model="screen_view" rounded :options="optScreenView"></q-btn-toggle>
        </q-card-section>

        <q-card-actions align="right" class="text-dark">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn
            size="sm"
            type="submit"
            outline
            rounded
            color="primary"
            label="Add File"
          />
        </q-card-actions>
      </q-form>

      <div v-for="(item, index) in submitResult" :key="index">
        {{ item.value }}
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { ApiTransactionAssets } from "../../api/ApiTransactionAssets";
import TransDetAssetItem from "../../components/trans-assets/TransactionDetailAssetItem.vue";
import { Manager } from "@/api/Manager"

const api_trans_assets = new ApiTransactionAssets();
const manager = new Manager();

export default {
  components: {
    "trans-det-asset-item": TransDetAssetItem,
  },
  setup() {
    const store = useStore();
    let page = ref(store.getters["ast/page"]);
    const isAddDialFile = ref(false);
    const addFile = ref([]);
    const file = ref(null);
    const files = ref(null);
    const submitResult = ref([]);

    const screen_view = ref("kotak")


    const submitEmpty = ref(false);
    const isPremium = ref(false);

    const optScreenView = manager.selectView();
    const freeText = computed(function () {
      return store.getters["ast/freeText"];
    });

    const maxPage = computed(function () {
      var max = 1;
      if (store.getters["ast/maxPage"] !== null) {
        max = store.getters["ast/maxPage"];
      }
      return max;
    });

    const transId = computed(function () {
      var id = null;
      if (store.getters["ast/transAssetId"] !== null) {
        id = store.getters["ast/transAssetId"];
      }
      return id;
    });

    watch(transId, function (newValues) {
      if (newValues != null) {
        page.value = 1;
      }
    });

    function showDialogCreateTransDet() {
      isPremium.value = false;
      addFile.value = [];
      isAddDialFile.value = true;
    }

    function btnPage() {
      store.dispatch("ast/loadNextPageDetails", {
        id: transId.value,
        page: page.value,
      });
    }

    function addFileTrans(evt) {
      const formUpload = new FormData(evt.target);
      const formData = new FormData();
      console.log(store.getters["ast/transAssetId"]);
      const premiumData = isPremium.value ? 1 : 0;
      formData.append("transaction_id", store.getters["ast/transAssetId"]);
      formData.append("image", addFile);
      formData.append("is_premium", premiumData);
      formData.append("screen_view", screen_view.value)

      if (addFile.value && addFile.value.length > 0) {
        for (let i = 0; i < addFile.value.length; i++) {
          formData.append("files[" + i + "]", addFile.value[i]);
        }
      }
      const data = [];

      for (const [name, value] of formUpload.entries()) {
        if (value.name.length > 0) {
          data.push({
            name,
            value: value.name,
          });
          formData.append(name, value);
        }
      }

      api_trans_assets
        .storeUploadFile(formData, store.getters["token"])
        .then((res) => {
          if (res.data) {
            store.dispatch("ast/loadTransAsset");
            setTimeout(() => {
              isAddDialFile.value = false;
              store.dispatch(
                "ast/loadDetailTrans",
                store.getters["ast/transAssetId"]
              );
            }, 1200);
          }
        });

      //   submitResult.value = data;
    }

    const subItems = computed(function () {
      let data = [];
      // console.log("computed getters " + store.getters["ast/transDetail"]);
      if (store.getters["ast/transDetail"] != "") {
        data = JSON.parse(store.getters["ast/transDetail"]);
      }
      console.log(data)
      return data;
    });

    return {
      page,
      isAddDialFile,
      addFile,
      files,
      file,
      submitResult,
      submitEmpty,
      subItems,
      isPremium,
      transId,
      maxPage,
      freeText,
      screen_view,
      optScreenView,

      showDialogCreateTransDet,
      addFileTrans,
      btnPage,
    };
  },
};
</script>
