<template>
  <q-layout>
    <q-page-container>
      <router-view name="header"></router-view>
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
// import HelloWorld from './components/HelloWorld.vue'
// import Header from "@/components/layout/Header.vue";

export default {
  name: "LayoutDefault",

  components: {
    // Header
  },
  setup() {
    return {
      headerShow: ref(false),
    };
  },
};
</script>

<style >
.route-enter-from {
  opacity: 0;
  transform: translateY(-40px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(40px);
}

.route-enter-active {
  transition: all 0.6s ease-out;
}

.route-leave-active {
  transition: all 0.6s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}
</style>
