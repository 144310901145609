<template>
  <div class="q-pa-md">
    <div class="row">
      <div class="col-3">
        <q-card
          :class="
            visibleCategory
              ? 'q-ml-sm q-mr-sm text-white card-loading-cat'
              : 'q-ml-sm q-mr-sm text-white '
          "
          style="background: radial-gradient(circle, #014a88 0%, #2196f3 100%)"
        >
          <div class="q-pa-sm">
            <q-card-section>
              <transition
                appear
                enter-active-class="animated fadeIn"
                leave-active-class="animated fadeOut"
              >
                <div v-show="showCategoryData">
                  <q-list separator>
                    <q-item
                      v-ripple
                      v-for="category in categories"
                      :key="category.id"
                      ><q-item-section
                        ><q-item-label class="text-body1"
                          ><b>{{ category.name }}</b></q-item-label
                        >
                        <q-item-label caption class="text-white"
                          >Total Sub : {{ category.sub_category.length }}
                        </q-item-label></q-item-section
                      >
                    </q-item>
                  </q-list>
                </div>
              </transition>
            </q-card-section>
            <q-inner-loading :showing="visibleCategory">
              <q-spinner-gears size="50px" color="white" />
            </q-inner-loading>
          </div>
        </q-card>
      </div>
      <div class="col-2">
        <q-card
          :class="
            visibleCategory
              ? 'q-ml-sm q-mr-sm text-white card-loading-ast'
              : 'q-ml-sm q-mr-sm text-white '
          "
          style="background: radial-gradient(circle, #014a88 0%, #2196f3 100%)"
        >
          <q-card-section horizontal>
            <transition
              appear
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <div v-show="showAssetsData">
                <q-card-section class="col-8 q-pt-md">
                  <b>Total Asset</b>
                </q-card-section>
                <q-separator vertical color="white" />
                <q-card-section class="col-2 text-h3">{{
                  total_assets
                }}</q-card-section>
              </div>
            </transition>
          </q-card-section>

          <q-inner-loading :showing="visibleAssets">
            <q-spinner-gears size="50px" color="white" />
          </q-inner-loading>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
import { ApiDashboard } from "../../api/ApiDashboard";
import { useStore } from "vuex";
export default {
  setup() {
    const api_dash = new ApiDashboard();
    const total_assets = ref(0);
    const showCategoryData = ref(false);
    const visibleCategory = ref(false);
    const showAssetsData = ref(false);
    const visibleAssets = ref(false);
    const categories = ref([]);
    const store = useStore();

    onMounted(function () {
      fetchTotalCategories();
      fetchTtotalAssets();
    });
    function fetchTtotalAssets() {
      visibleAssets.value = true;
      showAssetsData.value = false;
      api_dash.loadTotalAssets(store.getters["token"]).then((res) => {
        if (res.data) {
          total_assets.value = res.data;
          visibleAssets.value = false;
          showAssetsData.value = true;
        }
      });
    }

    function fetchTotalCategories() {
      visibleCategory.value = true;
      showCategoryData.value = false;
      api_dash.loadTotalCategories(store.getters["token"]).then((res) => {
        if (res.data) {
          categories.value = res.data;
          showCategoryData.value = true;
          visibleCategory.value = false;
        }
      });
    }

    return {
      total_assets,
      categories,
      showCategoryData,
      visibleCategory,
      showAssetsData,
      visibleAssets,
    };
  },
};
</script>

<style scoped>
.card-loading-cat {
  min-height: 150px;
}
.card-loading-ast {
  min-height: 100px;
}
</style>
