import { createStore } from 'vuex'
import categoryModule from './modules/category';
import { useStorage } from "vue3-storage";
import transactionAssetModule from './modules/transactionAsset';

const use_storage = new useStorage()
const store = createStore({
    modules: {
        cat: categoryModule,
        ast: transactionAssetModule
    },
    state: {
        token: null,
        isCode: false,
        userName: '',
        date: null
    },
    mutations: {
        setUserData(state, payload) {
            state.token = payload.token
            state.userName = payload.userName
            state.date = payload.date
            state.isCode = payload.is_otp
            
        },
        updateVerifyCode(state, payload) {
            state.isCode = payload
        }
    },
    actions: {
        storeToken(context, payload) {
            use_storage.setStorageSync('authData', payload)
            context.commit('setUserData', payload)
        },
        changeVerifyCode(context, payload) {
            context.commit('updateVerifyCode', payload)
        },
        tryLogin(context){
            const userData = use_storage.getStorageSync('authData')
            if(userData){
                context.commit('setUserData', userData)
            }
            
        }
    },
    getters: {
        token(state) {
            return state.token
        },
        isCode(state) {
            return state.isCode
        },
        userName(state) {
            return state.userName
        },
        dateExpired(state) {
            return state.date
        }, 
        isAuthenticeted(state){
            return !!state.token
        }
    
    }
})

export default store;
