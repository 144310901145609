<template>
  <q-item>
    <q-item-section>
      <q-item-label>{{ name }}</q-item-label>
      <q-item-label caption>{{ username }}</q-item-label>
    </q-item-section>

    <q-item-section side>
      <div class="q-gutter-xs">
        <q-btn
          size="sm"
          outline
          round
          icon="edit"
          color="green"
          class="q-mr-sm"
          @click="dialUptUser"
        ></q-btn>
        <q-btn
          size="sm"
          color="red"
          outline
          round
          icon="delete"
          @click="dialDeleteUser"
        ></q-btn>
      </div>
    </q-item-section>
  </q-item>

  <q-dialog v-model="isDeleteDialUser">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-red text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        {{ name }}
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          label="Delete User"
          color="red"
          @click="btnDeluser"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  
</template>

<script>
import { ref } from "vue";
export default {
  props: ["id", "name", "username"],
  emits: ["delUser"],
  setup(props, context) {
    const isDeleteDialUser = ref(false);
    function dialUptUser() {}

    function dialDeleteUser() {
      isDeleteDialUser.value = true;
      //   console.log(props.id);
    }

    function btnDeluser() {
      context.emit("delUser", props.id);
    }

    return {
      isDeleteDialUser,

      dialUptUser,
      btnDeluser,
      dialDeleteUser,
    };
  },
};
</script>
