<template>
  <q-page class="flex flex-center q-pa-md">
    <q-card class="my-card">
      <q-card-section class="bg-primary text-white cs-bordered">
        <div class="text-center">
          <div class="text-purple q-gutter-md q-pb-sm">
              <q-avatar round color="white">
                  <q-img src="../../assets/logo-labuhan-baru.png" class="q-ma-sm"/>
              </q-avatar>
          </div>
        </div>
        <div class="text-subtitle2 text-center">LABUHAN DIGITAL</div>
      </q-card-section>

      <q-card-actions class="q-pt-md">
        <div class="q-pa col-12">
          <q-form @submit="submitLogin">
            <div class="row">
              <div class="col-12">
                <q-input
                  rounded
                  outlined
                  placeholder="Username"
                  dense
                  v-model="username"
                  :rules="[
                    (val) => (val && val.length > 0) || 'Username is required',
                  ]"
                />
              </div>
            </div>
            <div class="row q-pt-sm">
              <div class="col-12">
                <q-input
                  rounded
                  outlined
                  type="password"
                  placeholder="Password"
                  dense
                  borderless
                  v-model="password"
                  :rules="[
                    (val) => (val && val.length > 0) || 'Password is required',
                  ]"
                />
              </div>
            </div>
            <div class="q-pt-lg q-pb-sm text-center">
              <div class="col">
                <q-btn
                  color="primary"
                  class="btn-fixed-width"
                  rounded
                  type="submit"
                  >LOGIN</q-btn
                >
              </div>
            </div>
          </q-form>
        </div>
      </q-card-actions>
    </q-card>
  </q-page>
</template>

<script>
import { ref } from "vue";
// import { useStorage } from "vue3-storage";
import { useStore } from "vuex";
import { ApiAuth } from "../../api/ApiAuth";
import { useQuasar, QSpinnerCube } from "quasar";
import router from "@/router";

export default {
  setup() {
    const username = ref(null);
    const password = ref(null);
    const api_auth = new ApiAuth();
    // const storage = useStorage();
    const store = useStore();
    const $q = useQuasar();

    function submitLogin() {
      $q.loading.show({
        spinnerSize: 80,
        boxClass: "bg-grey-2 text-grey-9 q-pa-xl",
        spinnerColor: "primary",
        spinner: QSpinnerCube,
      });

      //   console.log(api_auth);
      api_auth
        .login({ username: username.value, password: password.value })
        .then((res) => {
          if (res.data) {
            setTimeout(() => {
              store.dispatch("storeToken", res.data);
              $q.loading.hide();
              router.push({ name: "OTPInput" });
            }, 1200);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            console.log(err);
            $q.loading.hide();
          }, 2000);
        });
    }

    return { username, password, submitLogin };
  },
};
</script>

<style lang="scss" scoped>
.my-card {
  min-width: 400px;
}
.btn-fixed-width {
  width: 200px;
}
.q-card > div:first-child,
.q-card > img:first-child {
  border-radius: 0px 0px 45px 45px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.q-field--outlined:hover .q-field__control:before {
  border: red;
}
input:hover {
  box-shadow: 0 0 10px #313131;
  border-color: 3px solid rgb(163, 39, 39);
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.slide-fade-leave-to,
.slide-fade-enter-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
