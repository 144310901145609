<template>
  <div class="col-6 q-pl-lg q-pr-lg">
    <div class="row">
      <div class="col-6">
        <q-btn rounded color="primary" size="sm" @click="showDialogCreateTrans"
          >CREATE</q-btn
        >
      </div>
      <div class="col-6">
        <div class="q-pt-sm text-right">Assets</div>
      </div>
    </div>
    <div class="row q-pt-sm">
      <div class="col-12">
        <q-input
          rounded
          outlined
          label="Search"
          dense
          v-model="searchAsset"
          @keyup="filterTrans"
        >
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>

    <div class="row q-pt-sm">
      <div class="col-12">
        <div v-if="items.length > 0">
          <trans-asset-item
            v-for="item in items"
            :key="item.id"
            :id="item.id"
            :premium="item.is_premium"
            :category="item.category"
            :sub_category="item.sub_category"
          ></trans-asset-item>
        </div>
      </div>
    </div>

    <div class="row q-pt-sm" v-if="items.length > 0">
      <div class="col-12">
        <q-pagination
          v-model="page"
          :max="maxPage"
          @click="btnPage()"
        ></q-pagination>
      </div>
    </div>
  </div>

  <q-dialog v-model="isAddDialoagCategory">
    <q-card style="min-width: 350px">
      <q-form @submit="CreateTransaction">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">Dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-sm q-glutter-md">
          <q-select
            dense
            rounded
            outlined
            v-model="selectCat"
            :options="selectItemCategories"
            label="-Categories-"
            map-options
            use-input
            emit-value
            @filter="filterCategories"
            lazy-rules
            :rules="[
              (val) => (val !== null && val !== '') || 'Category is required',
            ]"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>
          <q-select
            dense
            rounded
            outlined
            v-model="selectSubCat"
            :options="selectItemSubCategory"
            label="Sub Categories"
            map-options
            use-input
            emit-value
            @filter="filterCategories"
            lazy-rules
            :rules="[
              (val) =>
                (val !== null && val !== '') || 'SubCategory is required',
            ]"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>
          <div class="text-h6 text-red" v-if="isDuplicate">Duplicate</div>
        </q-card-section>

        <q-card-actions align="right" class="text-dark">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn
            size="sm"
            outline
            rounded
            color="primary"
            label="Add Trans Asset"
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed, onBeforeMount, watch } from "vue";
import { useStore } from "vuex";
import TransAssetItem from "../../components/trans-assets/TransactionAssetItem.vue";
import { ApiCategory } from "../../api/ApiCategory";
import { ApiTransactionAssets } from "../../api/ApiTransactionAssets";

const api_category = new ApiCategory();
const api_trans_assets = new ApiTransactionAssets();
export default {
  components: {
    TransAssetItem,
  },

  setup() {
    const isAddDialoagCategory = ref(false);
    const store = useStore();
    let page = ref(store.getters["ast/pageTrs"]);
    const selectCat = ref(null);
    const selectSubCat = ref(null);
    const searchAsset = ref(null);
    const filterAsset = ref(null);
    let selectItemCategories = ref([]);
    let selectItemSubCategory = ref([]);
    const defaulItemCategories = ref([]);
    const isDuplicate = ref(false);
    onBeforeMount(() => {
      store.dispatch("tryLogin");
      store.dispatch("ast/loadTransAsset");
      store.dispatch("cat/loadCategories");
      fectchCategories();
    });

    watch(selectCat, function (newValues) {
      if (newValues === null) {
        selectItemSubCategory.value = [];
      } else {
        fetchSubCategory(newValues);
      }
    });

    const maxPage = computed(function () {
      var max = 1;
      if (store.getters["ast/maxPageTrs"] !== null) {
        max = store.getters["ast/maxPageTrs"];
      }
      return max;
    });

    function fectchCategories() {
      api_category
        .selectCategories(store.getters["token"])
        .then((res) => {
          if (res.data) {
            const dt = JSON.stringify(res.data);
            selectItemCategories.value = JSON.parse(dt);
            defaulItemCategories.value = JSON.parse(dt);
          }
        })
        .catch((err) => {
          console.log("error " + err);
        });
    }

    function btnPage() {
     console.log(page.value)
      store.dispatch("ast/loadNext", {
        page: page.value,
      });
    }

    

    function fetchSubCategory(id) {
      store.dispatch("cat/loadSubCategory", id);
      const dt = JSON.parse(store.getters["cat/subCategory"]);
      let arr = [];
      dt.forEach((el) => {
        arr.push({ label: el.name, value: el.id });
      });
      if (arr.length === 0) selectSubCat.value = null;
      selectItemSubCategory.value = arr;
    }

    const items = computed(function () {
      let data = [];
      if (store.getters["ast/transAsset"] != "") {
        data = JSON.parse(store.getters["ast/transAsset"]);
      }

      return data;
    });

    function filterTrans(evt) {
      if (evt.key === "Enter") {
        filterAsset.value = searchAsset.value;
      }
    }
    function showDialogCreateTrans() {
      selectCat.value = null;
      selectSubCat.value = null;
      isAddDialoagCategory.value = true;
      isDuplicate.value = false;
    }

    function filterCategories(val, update) {
      update(() => {
        const filter = val.toLowerCase();
        const selectFilter = selectItemCategories.value;
        if (filter === "") {
          selectItemCategories.value = defaulItemCategories.value;
        } else {
          selectItemCategories.value = selectFilter.filter(
            (x) => x.label.toLowerCase().indexOf(filter) > -1
          );
        }
      });
    }

    function CreateTransaction() {
      api_trans_assets
        .addTransAsset(
          {
            category_id: parseInt(selectCat.value),
            subcategory_id: parseInt(selectSubCat.value),
          },
          store.getters["token"]
        )
        .then((res) => {
          if (res.data) {
            if (res.data === "duplicate") {
              isDuplicate.value = true;
            } else {
              store.dispatch("ast/loadTransAsset");
              isAddDialoagCategory.value = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      isAddDialoagCategory,
      items,
      page,
      selectCat,
      selectItemCategories,
      selectItemSubCategory,
      selectSubCat,
      isDuplicate,
      searchAsset,
      maxPage,

      showDialogCreateTrans,
      CreateTransaction,
      filterCategories,
      filterTrans,
      btnPage
    };
  },
};
</script>
