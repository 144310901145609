<template>
  <q-list bordered separator>
    <q-item>
      <q-item-section> {{ order_num }} : {{ name }} </q-item-section>
      <q-item-section side>
        <div class="q-gutter-xs">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            color="green"
            class="q-mr-sm"
            @click="dialUptSubCategory"
          ></q-btn>
          <q-btn
            size="sm"
            color="red"
            outline
            round
            icon="delete"
            @click="dialDelCategory"
          ></q-btn>
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="isDeleteDialoagSubCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-red text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        {{ name }}
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          label="Delete Category"
          color="red"
          @click="btnDeleteSubCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isEditDialoagSubCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-green text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        <q-input
          dense
          rounded
          outlined
          placeholder="Add Category"
          v-model="subCategoryName"
        />

        <q-input
          type="number"
          class="q-mt-sm"
          dense
          rounded
          outlined
          placeholder="Urutan Ke "
          v-model="orderNumber"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          color="green"
          label="Update Category"
          @click="btnUpdateSubCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ApiCategory } from "../../api/ApiCategory";
export default {
  props: ["id", "name", "order_num"],
  setup(props) {
    const api_category = new ApiCategory();
    const store = useStore();

    const isDeleteDialoagSubCategory = ref(false);
    const isEditDialoagSubCategory = ref(false);
    const subCategoryName = ref(props.name);
    const orderNumber = ref(props.order_num);

    const categoryId = computed(function () {
      return store.getters["cat/categoryId"];
    });

    function showDetail() {
      store.dispatch("cat/loadSubCategory", props.id);
    }

    function dialUptSubCategory() {
      isEditDialoagSubCategory.value = true;
    }

    function dialDelCategory() {
      isDeleteDialoagSubCategory.value = true;
    }

    function btnDeleteSubCategory() {
      if (categoryId.value != null) {
        api_category
          .delSubCategory(props.id, store.getters["token"])
          .then((res) => {
            if (res.data) {
              isDeleteDialoagSubCategory.value = false;
              store.dispatch("cat/loadCategories");
              setTimeout(() => {
                store.dispatch("cat/loadSubCategory", categoryId.value);
              }, 2300);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    function btnUpdateSubCategory() {
      if (categoryId.value != null) {
        api_category
          .updateSubCategory(
            props.id,
            {
              category_id: categoryId.value,
              name: subCategoryName.value,
              order_num: orderNumber.value,
            },
            store.getters["token"]
          )
          .then((res) => {
            if (res.data) {
              isEditDialoagSubCategory.value = false;
              store.dispatch("cat/loadCategories");
              setTimeout(() => {
                store.dispatch("cat/loadSubCategory", categoryId.value);
              }, 2300);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    return {
      isDeleteDialoagSubCategory,
      isEditDialoagSubCategory,
      subCategoryName,
      orderNumber,

      dialUptSubCategory,
      dialDelCategory,
      showDetail,
      btnDeleteSubCategory,
      btnUpdateSubCategory,
    };

    //     const store = useStore();
    //     function showDetail(){

    //         store.dispatch('cat/loadSubCategory', props.id)
    //         console.log(props.id);
    //     }

    //     return {showDetail}
  },
};
</script>
