<template>
  <div class="q-pa-lg">
    <div class="row">
      <category-list />
      <sub-category-list></sub-category-list>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import CategoryList from "./CategoryList.vue";
import { useStore } from "vuex";
import SubCategoryList from "./SubCategoryList.vue";

export default {
  components: {
    CategoryList,
    SubCategoryList,
  },

  setup() {
    const store = useStore();
    onMounted(function () {
      store.dispatch("tryLogin");
      store.dispatch("cat/loadCategories");
    });
  },
};
</script>
