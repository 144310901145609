<template>
  <q-page class="flex flex-center q-pb-xl">
    <q-card>
      <q-card-section class="header-section">
        <div class="text-h6 text-center">Input Code</div>
      </q-card-section>
      <q-separator />
      <q-card-section
        ><OtpInput
          input-classes="otp-input"
          separator="-"
          ref="otpCode"
          :num-inputs="4"
          :is-input-num="true"
          @on-complete="hadlerComplete"
      /></q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { ref } from "vue";
import OtpInput from "vue3-otp-input";
import { ApiAuth } from "../../api/ApiAuth";
import { useStore } from "vuex";
import { useStorage } from "vue3-storage";
import { useQuasar, QSpinnerCube } from "quasar";
import router from "@/router";

export default {
  components: {
    OtpInput,
  },

  setup() {
    const otpCode = ref(null);
    const storage = useStorage();
    const store = useStore();
    const api_auth = new ApiAuth();
    const $q = useQuasar();

    function submitOTP() {
      console.log(otpCode);
    }

    const hadlerComplete = (value) => {
      const dataToken = storage.getStorageSync("authData");
      checkOTP(value, dataToken.token);
    };

    function checkOTP(otp, token) {
      $q.loading.show({
        spinnerSize: 80,
        boxClass: "bg-grey-2 text-grey-9 q-pa-xl",
        spinnerColor: "primary",
        spinner: QSpinnerCube,
      });

      api_auth
        .otpCheck({ otp_code: otp }, token)
        .then((res) => {
          setTimeout(() => {
            if (res.data) {
              const dataToken = storage.getStorageSync("authData");
              dataToken.is_otp = true;
              storage.setStorageSync("authData", dataToken);
              console.log(dataToken)
              store.dispatch("storeToken", dataToken);

              router.replace({ name: "Home" });
            } else {
              otpCode.value.clearInput();
            }
            $q.loading.hide();
          }, 2000);
        })
        .catch((err) => {
          setTimeout(() => {
            console.log(err);
            $q.loading.hide();
          }, 2000);
        });
    }

    return { otpCode, submitOTP, hadlerComplete };
  },
};
</script>

<style scoped>
.header-section {
  background-color: #2196f3;
  color: white;
}
</style>
