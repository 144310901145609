<template>
  <q-list bordered separator :style="isSelected==true?'background-image: linear-gradient(to bottom right,white,#E3EEFF);':''">
    <q-item>
      <q-item-section>
        <q-item-label
          >{{ category.name }} / {{ sub_category.name }}</q-item-label>
        <q-item-label lines="1"
          ><q-btn dense flat color="primary" size="sm" @click="showDetail"
            >Detail</q-btn
          ></q-item-label
        >
      </q-item-section>
      <q-item-section side>
        <div class="q-gutter-xs">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            color="green"
            class="q-mr-sm"
            @click="dialUptTrans"
          ></q-btn>
          <q-btn
            size="sm"
            color="red"
            outline
            round
            icon="delete"
            @click="dialDeleteTrans"
          ></q-btn>
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="isDeleteDialTrans">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-red text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        {{ category.name }} / {{ sub_category.name }}
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          label="Delete Transaction"
          color="red"
          @click="btnDelTransaction"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isUpdateDialTrans">
    <q-card style="min-width: 350px">
      <q-form @submit="UpdateTransaction">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">Dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-sm q-glutter-md">
          <q-select
            dense
            rounded
            outlined
            v-model="selectCat"
            :options="selectItemCategories"
            label="-Categories-"
            map-options
            use-input
            emit-value
            @filter="filterCategories"
            lazy-rules
            :rules="[
              (val) => (val !== null && val !== '') || 'Category is required',
            ]"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>
          <q-select
            dense
            rounded
            outlined
            v-model="selectSubCat"
            :options="selectItemSubCategory"
            label="Sub Categories"
            map-options
            use-input
            emit-value
            @filter="filterCategories"
            lazy-rules
            :rules="[
              (val) =>
                (val !== null && val !== '') || 'SubCategory is required',
            ]"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey"> No results </q-item-section>
              </q-item>
            </template>
          </q-select>

          <div class="text-h6 text-red" v-if="isDuplicate">Duplicate</div>
        </q-card-section>

        <q-card-actions align="right" class="text-dark">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn
            size="sm"
            outline
            rounded
            color="primary"
            label="Update Trans Asset"
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, onBeforeMount, watch, computed } from "vue";
import { ApiTransactionAssets } from "../../api/ApiTransactionAssets";
import { useStore } from "vuex";
import { ApiCategory } from "../../api/ApiCategory";

const api_trans_assets = new ApiTransactionAssets();
const api_category = new ApiCategory();
export default {
  props: ["id", "category", "sub_category", "premium"],

  setup(props) {
    const isDeleteDialTrans = ref(false);
    const isUpdateDialTrans = ref(false);
    const selectCat = ref(null);
    const selectSubCat = ref(null);
    let selectItemCategories = ref([]);
    let selectItemSubCategory = ref([]);
    const defaulItemCategories = ref([]);
    const isPremium = ref(false);
    const isDuplicate = ref(false);
    const isSelected = ref(false)

    const store = useStore();

    onBeforeMount(() => {
    //   store.dispatch("tryLogin");
      fectchCategories();
    });

  

    const transId = computed(function(){
        
        return store.getters['ast/transAssetId']
    })

     const subItems = computed(function () {
      let data = [];
      if (store.getters["ast/transDetail"] != "") {
        data = JSON.parse(store.getters["ast/transDetail"]);
      }
      return data;
    });


    watch([selectCat, transId], function ([newSelectCat, newTransId]) {
        
      if (newSelectCat === null) {
        selectItemSubCategory.value = [];
      } else {
        fetchSubCategory(newSelectCat);
      }

      if(newTransId==props.id){
            isSelected.value = true
        }else{
            isSelected.value = false
        }
    });


   

    function showDetail() {
      store.dispatch("ast/loadDetailTrans", props.id);
    }

    function fectchCategories() {
      api_category
        .selectCategories(store.getters["token"])
        .then((res) => {
          if (res.data) {
            const dt = JSON.stringify(res.data);
            selectItemCategories.value = JSON.parse(dt);
            defaulItemCategories.value = JSON.parse(dt);
          }
        })
        .catch((err) => {
          console.log("error " + err);
        });
    }

    function fetchSubCategory(id) {
      store.dispatch("cat/loadSubCategory", id);
      const dt = JSON.parse(store.getters["cat/subCategory"]);
      let arr = [];
      dt.forEach((el) => {
        arr.push({ label: el.name, value: el.id });
      });
      if (arr.length === 0) selectSubCat.value = null;

      selectItemSubCategory.value = arr;
    }

    function filterCategories(val, update) {
      update(() => {
        const filter = val.toLowerCase();
        const selectFilter = selectItemCategories.value;
        if (filter === "") {
          selectItemCategories.value = defaulItemCategories.value;
        } else {
          selectItemCategories.value = selectFilter.filter(
            (x) => x.label.toLowerCase().indexOf(filter) > -1
          );
        }
      });
    }

    function UpdateTransaction() {
      const premiumData = isPremium.value ? "1" : "0";

      api_trans_assets
        .updateTransAsset(
          props.id,
          {
            category_id: parseInt(selectCat.value),
            subcategory_id: parseInt(selectSubCat.value),
            is_premium: premiumData,
          },
          store.getters["token"]
        )
        .then((res) => {
          if (res.data) {
            if (res.data === "duplicate") {
              isDuplicate.value = true;
            } else {
              store.dispatch("ast/loadTransAsset");
              isUpdateDialTrans.value = false;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function dialDeleteTrans() {
      isDeleteDialTrans.value = true;
    }

    function dialUptTrans() {
      selectCat.value = props.category.id;
      selectSubCat.value = props.sub_category.id;
      props.premium == 1 ? (isPremium.value = true) : (isPremium.value = false);
      isUpdateDialTrans.value = true;
    }

    function btnDelTransaction() {
      api_trans_assets
        .delTransAsset(props.id, store.getters["token"])
        .then((res) => {
          if (res.data) {
            store.dispatch("ast/loadTransAsset");
            isDeleteDialTrans.value = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      isDeleteDialTrans,
      isPremium,
      isDuplicate,
      selectItemCategories,
      selectItemSubCategory,
      isUpdateDialTrans,
      selectSubCat,
      selectCat,
      subItems,
      isSelected,
      

      filterCategories,

      dialDeleteTrans,
      btnDelTransaction,
      dialUptTrans,
      UpdateTransaction,
      showDetail
    };
  },
};
</script>
