import axios from "axios";

const API_URL = process.env.VUE_APP_URL_API;

export class ApiUser{


    constructor() { }

    async loadAlluser(token) {
        return await axios.get(API_URL + 'users', this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async addUser(data, token) {
        return await axios.post(API_URL + 'register', data,this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async delUser(id, token) {
        return await axios.delete(API_URL + 'users/delete/'+id, this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    headers_api(token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }
    }
}