import axios from "axios";

const API_URL = process.env.VUE_APP_URL_API;
export class ApiDashboard{

    constructor() { }

    async loadTotalAssets(token) {
        return await axios.get(API_URL + 'dashboard/total-aseets', this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    async loadTotalCategories(token) {
        return await axios.get(API_URL + 'dashboard/total-categories', this.headers_api(token)).catch((err) => { throw err.response.status });
    }

    headers_api(token) {
        return {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json'
            }
        }
    }
}