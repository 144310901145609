<template>
  <div class="q-pa-md">
    <div class="row">
      <trans-assets-list></trans-assets-list>
      <trans-assets-detail-list></trans-assets-detail-list>
    </div>
  </div>
</template>

<script>
import {onMounted} from 'vue';
import { useStore } from "vuex";
import TransAssetsList from "./TransAssetsList.vue";
import TransAssetsDetailList from "./TransAssetsDetailList.vue";
export default {
  components: {
    TransAssetsList,
    TransAssetsDetailList,
  },

  setup() {
    const store = useStore();
    onMounted(function () {
      store.dispatch("tryLogin");
    });
  },
};
</script>
