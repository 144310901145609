
import { ApiCategory } from "../../api/ApiCategory";

const api_category = new ApiCategory();
export default {
    namespaced: true,
    state() {
        return {
            items: [],
            itemsDetail: [],
            categoryId : null,
            selectCategory : [],
            name : ""
            
        }
    },
    mutations: {
        loadDataCategory(state, payload) {
            api_category.loadAllCategory(null,payload).then((res) => {
                if (res.data) {
                    state.items = JSON.stringify(res.data.data);
                    // console.log(state.items)
                }
            }).catch((err) => {
                state.items = []
                console.log('error ' + err);
            })
        },
        loadDataSubCategory(state, payload) {
            const items = JSON.parse(state.items)
            const dataDetail = items.find(x => x.id === payload.id)

            state.name = dataDetail.name
            state.itemsDetail = JSON.stringify(dataDetail.sub_category)
            
        },
        setCategoryId(state, payload){
            state.categoryId = payload.id
        },

        selectCategory(state, payload){
            api_category.selectCategories(payload).then((res) => {
                if (res.data) {
                    // const data = JSON.stringify(res.data)
                    state.selectCategory = JSON.stringify(res.data)
                }
            }).catch((err) => {
                state.items = []
                console.log('error ' + err);
            })
        }
    },
    actions: {
        loadCategories(context) {
            const token = context.rootGetters.token
            context.commit('loadDataCategory', token)
        },
        loadSubCategory(context, payload) {
            const id = {
                'id': payload
            }
            context.commit('setCategoryId', id)
            context.commit('loadDataSubCategory', id);
        },
        selectCategories(context){
            const token = context.rootGetters.token
            context.commit("selectCategory", token)
        }

    },

    getters: {
        category(state) {
            return state.items
        },
        subCategory(state) {
            return state.itemsDetail
        },
        categoryId(state){
            return state.categoryId
        },
        selectCategory(state){
            return state.selectCategory
        },
        name(state){
            return state.name
        }
    }
}