<template>
  <q-header reveal bordered>
    <div class="row q-pt-sm q-pb-sm">
      <div class="col-3">
        <div class="q-pl-xl">
          <q-btn round @click="toDashboard">
            <q-avatar round color="white">
              <q-img src="../../assets/logo-labuhan-baru.png" class="q-ma-sm" />
            </q-avatar>
          </q-btn>
        </div>
      </div>
      <div class="col-6">
        <nav>
          <ul>
            <li><router-link to="/">Dashboard</router-link></li>
            <li><router-link to="/category">Category</router-link></li>
            <li><router-link to="/transaction-assets">Asset</router-link></li>
            <li><router-link to="/user">User</router-link></li>
          </ul>
        </nav>
      </div>
      <div class="col-3">
        <q-btn flat round icon="logout" class="float-right q-pr-xl">
          <q-menu>
            <q-list>
              <q-item clickable v-close-popup>
                <q-item-section>Change Password</q-item-section>
              </q-item>
              <q-separator />
              <q-item clickable @click="logout">
                <q-item-section>Logout</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>
  </q-header>
</template>

<script>
import { useQuasar, QSpinnerCube } from "quasar";
import { useStorage } from "vue3-storage";
import router from "@/router";

export default {
  setup() {
    const use_storage = new useStorage();
    const $q = useQuasar();

    function logout() {
      $q.loading.show({
        spinnerSize: 80,
        boxClass: "bg-grey-2 text-grey-9 q-pa-xl",
        spinnerColor: "primary",
        spinner: QSpinnerCube,
      });

      setTimeout(() => {
        use_storage.clearStorageSync();
        router.replace({ path: "/login" });
        $q.loading.hide();
      }, 1200);
    }

    function toDashboard(){
        router.push({ path: "/" });
    }
    return {
      logout,
      toDashboard
    };
  },
};
</script>

<style scoped>
header {
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
}
header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #fff;
  display: inline-block;
  padding: 0.35rem 1.5rem;
  background-color: #595656;
  border: 1px solid transparent;
  border-radius: 30px;
}

li {
  margin: 0 0.5rem;
}

/* header {
  width: 100%;
  height: 5rem;
  background-color: #3d008d;
  display: flex;
  justify-content: center;
  align-items: center;
}

header a {
  text-decoration: none;
  color: #f391e3;
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border: 1px solid transparent;
}

a:active,
a:hover,
a.router-link-active {
  border: 1px solid #f391e3;
}

h1 {
  margin: 0;
}

h1 a {
  color: white;
  margin: 0;
}

h1 a:hover,
h1 a:active,
h1 a.router-link-active {
  border-color: transparent;
}

header nav {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
} */
</style>
