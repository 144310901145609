import { createRouter, createWebHistory } from 'vue-router';
import DashboardIndex from '../views/dashboard/DashboardIndex.vue';
import Login from '../views/auth/Login.vue';
import Category from '../views/category/CategoryIndex';
import Header  from '../components/layout/Header.vue';
import OTPInput from '../views/auth/OTPInput.vue';
import User from '../views/user/UserIndex.vue'
import TransAssetsIndex from '../views/TransactionAssets/TransAssetsIndex.vue';
import store from '../store/index';

const routes = [
    
    {
        path: '/',
        name: 'Home',
        components:{
            default: DashboardIndex,
            header : Header
        },
        meta:{authAndCode: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/user',
        name: 'User',
        components:{
            default: User,
            header : Header
        },
        meta:{authAndCode: true}
    },
    {
        path: '/generate-code',
        name: 'OTPInput',
        component: OTPInput,
        meta:{auth: true}
    },
    {
        path: '/category',
        name: 'Category',
        components:{
            default: Category,
            header : Header
        },
        meta:{authAndCode: true}
    },
    {
        path: '/transaction-assets',
        name: 'TransactionAsset',
        components:{
            default: TransAssetsIndex,
            header : Header
        },
        meta:{authAndCode: true}
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(function(to, _, next) {
    store.dispatch("tryLogin");
    // console.log(store.getters["isAuthenticeted"])
    // console.log(store.getters["token"])
    if(to.meta.auth && !store.getters["isAuthenticeted"]){
        next("/login")
    }else if(to.meta.authAndCode && !store.getters["isAuthenticeted"] && !store.getters["isCode"]){
        next("/login")
    }else if(to.meta.authAndCode && !store.getters["isCode"]){
        next("generate-code")
    }else{
        next()
    }
  });

export default router
