<template>
  <!-- <div class="col-6 q-pl-lg q-pr-lg" >
      <div class="flex flex-center q-pt-xl" v-show="categoryId">No Selected data...</div>
  </div> -->
  <div class="col-6 q-pl-lg q-pr-lg">
    <div class="row" v-if="categoryId">
      <div class="col-6">
        <q-btn
          rounded
          color="primary"
          size="sm"
          @click="showDialogCreateSubCategory"
          >CREATE</q-btn
        >
      </div>

      <div class="col-6">
        <div class="q-pt-sm text-right">
            Sub Category / {{categoryName}}
        </div>
        
      </div>
    </div>

    <div class="row q-pt-sm">
      <div class="col-12">
        <!-- <q-card flat class="q-pa-md"> -->
        <div v-if="subItems.length > 0">
          <sub-category-item
            v-for="item in subItems"
            :key="item.id"
            :id="item.id"
            :name="item.name"
            :order_num="item.order_num"
          ></sub-category-item>
        </div>
        <div v-else>
          <div class="flex flex-center q-pt-xl">No Have data...</div>
        </div>
        <!-- </q-card> -->
      </div>
    </div>
  </div>

  <q-dialog v-model="isAddDialoagSubCategory">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-primary text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        <q-input
          dense
          rounded
          outlined
          placeholder="Add SubCategory"
          v-model="subCategoryName"
        />
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          color="primary"
          label="Add Sub Category"
          @click="CreateSubCategory"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ApiCategory } from "../../api/ApiCategory";
import SubCategoryItem from "../../components/category/SubCategoryItem.vue";

export default {
  components: { SubCategoryItem },
  setup() {
    const store = useStore();
    const isAddDialoagSubCategory = ref(false);
    const subCategoryName = ref("");
    const isSubCategory = ref(store.getters["cat/categoryId"]);

    const api_category = new ApiCategory();

    const subItems = computed(function () {
      let data = [];
      //   console.log("computed getters " + store.getters["cat/subCategory"]);
      if (store.getters["cat/subCategory"] != "") {
        data = JSON.parse(store.getters["cat/subCategory"]);
      }
      return data;
    });

    const categoryId = computed(function () {
      var category = null;
      if (store.getters["cat/categoryId"] !== null) {
        category = store.getters["cat/categoryId"];
      }
      return category;
    });

    const categoryName = computed(function(){
        let name = ""
        if (store.getters["cat/name"] !== null) {
        name = store.getters["cat/name"];
      }

      return name

    })

    function showDialogCreateSubCategory() {
      isAddDialoagSubCategory.value = true;
    }

    function CreateSubCategory() {
      if (categoryId.value != null) {
        api_category
          .addSubCategory(
            { category_id: categoryId.value, name: subCategoryName.value },
            store.getters["token"]
          )
          .then((res) => {
            if (res.data) {
              isAddDialoagSubCategory.value = false;
              store.dispatch("cat/loadCategories");
              setTimeout(() => {
                store.dispatch("cat/loadSubCategory", categoryId.value);
              }, 2300);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            subCategoryName.value = "";
          });
      }
    }

    return {
      isAddDialoagSubCategory,
      subCategoryName,
      isSubCategory,
      categoryId,
      subItems,
      categoryName,


      showDialogCreateSubCategory,
      CreateSubCategory,
    };
  },
};
</script>

<style scoped>
.q-card {
  border: 1px solid #2196f3;
}
.q-field--outlined .q-field__control {
  border: 1px solid #2196f3;
}
</style>
