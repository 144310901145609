import axios  from "axios";

const API_URL = process.env.VUE_APP_URL_API;
export class ApiAuth{

    constructor(){}

    async login(userData){
     return await axios.post(API_URL+'login', userData).catch((err)=> {throw err.response.status});
    }

    async otpCheck(otp, token){
        return await axios.post(API_URL+'generate-code', otp, this.headers_api(token)).catch((err)=> {throw err.response.status});
    }

    test(){
        return "OKE";
    }


    headers_api(token){
        return {
            headers:{
                Authorization: `Bearer ${token}`,
                Accept : 'application/json'
            }
        }
    }
}