import { ApiTransactionAssets } from "../../api/ApiTransactionAssets";

const api_trans_asset = new ApiTransactionAssets();

export default {

    namespaced: true,
    state() {
        return {
            items: [],
            itemsDetail: [],
            transId: null,
            transAssetId : null,
            imageLink : null,
            maxPage : 0,
            page : 1,
            maxPageTrs: 0,
            pageTrs:1,
            freeText : "",
            screenView : ""
        }
    },
    mutations: {
        loadDataTransactionAsset(state, payload) {
            api_trans_asset.loadAllTransactionAsset(false,null,null,payload).then((res) => {
                if (res.data) {
                    state.items = JSON.stringify(res.data.data);
                    const image_url = JSON.parse(state.items)
                    state.pageTrs=1
                    state.maxPageTrs = res.data.last_page
                    // console.log(state.items+" 1.  loadDataTransactionAsset")
                    if(image_url.length>0){
                        state.imageLink = image_url[0].image_link
                    }
                }
            }).catch((err) => {
                state.items = []
                console.log('error ' + err);
            })
        },
        setTransAssetId(state, payload){
            state.transAssetId =  payload.id
        },
        setTransId(state, payload){
            state.transId = payload
        },
        loadDataDetailTrans(state, payload){
            state.itemsDetail = []
            state.page = 1
            const items = JSON.parse(state.items)
            const dataDetail = items.find(x=>x.id===payload.id)
            state.freeText = dataDetail.free_text
            api_trans_asset.searchFile(false, null,{category_id: dataDetail.category_id, subcategory_id: dataDetail.subcategory_id}, payload.token).then((res)=>{
                if(res.data){
                    state.maxPage = res.data.last_page
                    state.itemsDetail = JSON.stringify(res.data.data)
                }   
            })
        },
        loadDatanextPageDetails(state, payload){
            state.itemsDetail = []
            const items = JSON.parse(state.items)
            const dataDetail = items.find(x=>x.id===payload.id)
            api_trans_asset.searchFile(true, payload.page, {category_id: dataDetail.category_id, subcategory_id: dataDetail.subcategory_id}, payload.token).then((res)=>{
                if(res.data){
                    state.itemsDetail = JSON.stringify(res.data.data)
                }   
            })
        },

        loadDataNextPage(state, payload){

            api_trans_asset.loadAllTransactionAsset(true,payload.page,null,payload.token).then((res) => {
                if (res.data) {
                    state.items = JSON.stringify(res.data.data);
                    const image_url = JSON.parse(state.items)
                    if(image_url.length>0){
                        state.imageLink = image_url[0].image_link
                    }
                }
            })
        }
    },
    actions: {
        loadTransAsset(context){
            const token = context.rootGetters.token
            context.commit('loadDataTransactionAsset', token);
        },
        loadDetailTrans(context, payload){
            const id = {
                'id' : payload,
                'token' :context.rootGetters.token
            }

            context.commit('setTransAssetId', id)
            context.commit('loadDataDetailTrans', id)
        },
        loadNextPageDetails(context, payload){
            const data = {
                'id' : payload.id,
                'page': payload.page,
                'token' :context.rootGetters.token
            }
            context.commit('loadDatanextPageDetails', data)
        },
        loadNext(context, payload){
            const data = {
                'page': payload.page,
                'token' :context.rootGetters.token
            }
            context.commit('loadDataNextPage', data)
        }
        
    },
    getters: {
        transAsset(state){
            return state.items
        },
        transId(state){
            return state.transId
        },
        transAssetId(state){
            return state.transAssetId
        },
        transDetail(state){
            return state.itemsDetail
        },
        imageLink(state){
            return state.imageLink
        },
        maxPage(state){
            return state.maxPage
        },
        page(state){
            return state.page
        },
        maxPageTrs(state){
            return state.maxPageTrs
        },
        pageTrs(state){
            return state.pageTrs
        },
        freeText(state){
            return state.freeText
        },
        screenView(state){
            return state.screenView
        }
    }
}