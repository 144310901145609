<template>
  <q-list bordered separator >
    <q-item>
      <q-item-section avatar>
        <q-avatar square>
          <img :src="imageUrl" />
        </q-avatar>
      </q-item-section>
      <q-item-section>
        <q-item-label>{{ name }}</q-item-label>
        <q-item-label caption
          ><q-badge v-if="premium == 1" rounded color="green">
            <q-icon name="star"></q-icon> Premium</q-badge
          >
          <q-badge class="q-glutter-xs" rounded><q-icon name="settings_overscan"></q-icon> &nbsp; {{screen}}</q-badge>
           <div class="text-caption">Created Date : {{date}}</div></q-item-label
        ></q-item-section
      >
      <q-item-section side>
        <div class="q-gutter-xs">
          <q-btn
            size="sm"
            outline
            round
            icon="edit"
            color="green"
            class="q-mr-sm"
            @click="dialUpSvg"
          ></q-btn>
          <q-btn
            size="sm"
            color="red"
            outline
            round
            icon="delete"
            @click="dialDelSvg"
          ></q-btn>
        </div>
      </q-item-section>
    </q-item>
  </q-list>

  <q-dialog v-model="isUpdateDialTrans">
    <q-card style="min-width: 350px">
      <q-form @submit="UpdateStatus">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">Dialog</div>
        </q-card-section>

        <q-card-section class="q-pt-sm q-glutter-md">
          <q-checkbox v-model="isPremium" label="Premium" color="green" />
          <br/>

          <q-btn-toggle v-model="screen_view" rounded :options="optScreenView"></q-btn-toggle>
        </q-card-section>

        <q-card-actions align="right" class="text-dark">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn
            size="sm"
            outline
            rounded
            color="primary"
            label="Update Trans Asset"
            type="submit"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>

  <q-dialog v-model="isDeleteSvg">
    <q-card style="min-width: 350px">
      <q-card-section class="bg-red text-white">
        <div class="text-h6">Dialog</div>
      </q-card-section>

      <q-card-section class="q-pt-sm">
        <img :src="imageUrl" style="max-height: 80px" /> <br />
        {{ name }}<br />
      </q-card-section>

      <q-card-actions align="right" class="text-dark">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn
          size="sm"
          outline
          rounded
          label="Delete Svg"
          color="red" 
          @click="btnDelSvg"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ApiTransactionAssets } from "../../api/ApiTransactionAssets";
import { Manager } from "@/api/Manager"

const api_trans_assets = new ApiTransactionAssets();
const manager = new Manager();
export default {
  props: ["id", "name", "link", "premium", "date", "screen"],

  setup(props) {
   
    const store = useStore();
    const isDeleteSvg = ref(false);
    const isPremium = ref(props.premium == 1 ? true : false);
    const isUpdateDialTrans = ref(false);
    const screen_view = ref("")
    
    const optScreenView = manager.selectView();
    
    

    const imageUrl = computed(function () {
      // console.log(store.getters["ast/imageLink"])
      return store.getters["ast/imageLink"] + "/" + props.name;
    });

    const transId = computed(function () {
      return store.getters["ast/transAssetId"];
    });



    function dialDelSvg() {
      isDeleteSvg.value = true;
    }

    function dialUpSvg() {
      isUpdateDialTrans.value = true;
      screen_view.value = props.screen
    }

    function UpdateStatus() {
      var data = { is_premium: isPremium.value == true ? 1 : 0, 'screen_view': screen_view.value };
      api_trans_assets
        .updateTransUpdateDetail(props.id, data, store.getters["token"])
        .then((res) => {
          if (res.data) {
            isUpdateDialTrans.value = false;
            store.dispatch("ast/loadTransAsset");
            setTimeout(() => {
              store.dispatch("ast/loadDetailTrans", transId.value);
            }, 1200);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function btnDelSvg() {
      api_trans_assets
        .delTransSvg(props.id, store.getters["token"])
        .then((res) => {
          if (res.data) {
            isDeleteSvg.value = false;
            store.dispatch("ast/loadTransAsset");
            setTimeout(() => {
              store.dispatch("ast/loadDetailTrans", transId.value);
            }, 1200);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      isDeleteSvg,
      isPremium,
      imageUrl,
      isUpdateDialTrans,
      optScreenView,
      screen_view,
      
      

      dialDelSvg,
      btnDelSvg,
      dialUpSvg,
      UpdateStatus,
    };
  },
};
</script>
